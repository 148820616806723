<template>
  <div
    class="customizer d-none d-md-block"
    :class="{'open': isCustomizerOpen}"
  >
    <b-link
      :class="'customizer-toggle d-flex align-items-center justify-content-center '.concat(bgLink)"
      @click="isCustomizerOpen = !isCustomizerOpen"
    >
      <feather-icon
        icon="PhoneIcon"
        size="15"
      />
    </b-link>
    <!-- /Toggler -->

    <!-- Header -->
    <div class="customizer-section d-flex justify-content-between align-items-center">
      <div>
        <h4 class="text-uppercase mb-0">
          Discador Fastway
        </h4>
      </div>
      <feather-icon
        icon="XIcon"
        size="18"
        class="cursor-pointer"
        @click="isCustomizerOpen = !isCustomizerOpen"
      />
    </div>

    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="ps-customizer-area scroll-area"
    >
      <div v-if="status === ''">
        <div class="customizer-section justify-content-between align-items-center">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            @click="ping"
          >
            Conectar
          </b-button>
        </div>
      </div>
      <div v-else>
        <div class="customizer-section">
          <b-row class="mb-1 justify-content-between align-items-center">
            <b-col>
              <h5>Status: </h5>
            </b-col>
            <b-col>
              <span>{{ status }}</span>
            </b-col>
          </b-row>

          <b-row class=" mb-1 justify-content-between align-items-center">
            <b-col>
              <h5>Status Complemento: </h5>
            </b-col>
            <b-col>
              <span>{{ statusComplemento }}</span>
            </b-col>
          </b-row>

          <b-row class="mb-1 justify-content-between align-items-center">
            <b-col>
              <h5>Tempo Conectado: </h5>
            </b-col>
            <b-col>
              <span>{{ tempoStatus }}</span>
            </b-col>
          </b-row>
          <div v-if="status === 'EM ATENDIMENTO'">

            <b-row class="mb-1 justify-content-between align-items-center">
              <b-col>
                <h5>CPF: </h5>
              </b-col>
              <b-col>
                <span><strong class="text-primary">{{ cpfCNPJ(cpfConectado) }}</strong></span>
              </b-col>
            </b-row>

            <b-row class="mb-1 justify-content-between align-items-center">
              <b-col>
                <h5>Telefone: </h5>
              </b-col>
              <b-col>
                <span><strong class="text-primary">{{ telefoneConectado }}</strong></span>
              </b-col>
            </b-row>

            <b-row class="justify-content-between align-items-center">
              <b-col>
                <h5>Código Cliente: </h5>
              </b-col>
              <b-col>
                <span><strong class="text-primary">{{ idCliente }}</strong></span>
              </b-col>
            </b-row>

            <div
              v-if="statusComplemento === 'MANUAL' || 'ATIVO'"
              class="mt-2"
            >
              <b-row>
                <b-col md="8">
                  <v-select
                    v-model="tabulacaoSelecionada"
                    label="nome"
                    :disabled="false"
                    :options="tabulacoes"
                  />
                </b-col>
                <b-col md="3">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-danger"
                    :disabled="tabulacaoDisabled"
                    @click="EncerrarLigacao"
                  >
                    Desligar
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </div>
          <div v-if="status === 'CLI_DESLIGOU'">
            <b-row>
              <b-col md="8">
                <v-select
                  v-model="tabulacaoSelecionada"
                  label="nome"
                  :disabled="false"
                  :options="tabulacoes"
                />
              </b-col>
              <b-col md="3">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-danger"
                  :disabled="tabulacaoDisabled"
                  @click="EncerrarLigacao"
                >
                  Desligar
                </b-button>
              </b-col>
            </b-row>
          </div>
          <div v-if="status === 'FINALIZACAO'">
            <b-row>
              <b-col md="3">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  @click="finalizar()"
                >
                  Desligar
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div v-if="status === 'OFF' || status === 'LOGIN INVALIDO'">
        <div class="customizer-section">
          <b-row class=" justify-content-between align-items-center">
            <b-col>
              <h5>Você não está logado</h5>
            </b-col>
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                @click="login"
              >
                Entrar
              </b-button>
            </b-col>
          </b-row>
        </div>
      </div>
      <div v-if="status === 'CONECTADO'">
        <!--  -->
      </div>
      <div v-if="status === 'EM PAUSA' || status === 'CONECTADO'">
        <div class="customizer-section justify-content-between align-items-center">
          <label>Pausas</label>
          <b-row>
            <b-col md="8">
              <v-select
                v-model="pausaSelecionada"
                label="nome"
                :disabled="pausaDisabled"
                :options="pausas"
              />
            </b-col>
            <b-col md="3">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="!pausaSelecionada"
                @click="fimPausa"
              >
                Fim
              </b-button>
            </b-col>
          </b-row>
        </div>
      </div>
      <div v-if="status === 'MODO MANUAL'">
        <div class="customizer-section justify-content-between align-items-center">
          <label>Número</label>
          <b-row class="mb-1">
            <b-col>
              <b-form-input
                v-model="numeroDiscagem"
                type="number"
              />
            </b-col>
          </b-row>
          <label>CPF Cliente</label>
          <b-row class="mb-1">
            <b-col>
              <b-form-input
                v-model="cpfDiscagem"
                type="number"
              />
            </b-col>
          </b-row>
          <label>ID Cliente</label>
          <b-row class="mb-1">

            <b-col>
              <b-form-input
                v-model="chaveDiscagem"
                type="number"
              />
            </b-col>

            <b-col
              md="4"
              class="text-right"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-success"
                :disabled="numeroDiscagem.length < 10 || numeroDiscagem.length > 12"
                @click="discarManual"
              >
                Ligar
              </b-button>
            </b-col>

          </b-row>
        </div>
      </div>
      <div v-if="status === 'DISCAGEM MANUAL'">
        <div class="customizer-section justify-content-between align-items-center">
          <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                size="sm"
                @click="cancelaDiscagem()"
              >
                Desligar
              </b-button>
            </b-col>
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                size="sm"
                @click="cancelaDiscagem('NAO ATENDE')"
              >
                Não Atende
              </b-button>
            </b-col>
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                size="sm"
                @click="cancelaDiscagem('CAIXA POSTAL')"
              >
                Caixa Postal
              </b-button>
            </b-col>
          </b-row>
        </div>
      </div>
      <div v-if="status === 'CONECTADO'">
        <div class="customizer-section justify-content-between align-items-center">
          <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-danger"
                @click="logoff"
              >
                Sair
              </b-button>
            </b-col>
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                @click="setDiscagemManual"
              >
                Modo Manual
              </b-button>
            </b-col>
            <b-col v-if="status === 'MODO MANUAL'">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="modoDiscador"
              >
                Modo Discador
              </b-button>
            </b-col>
          </b-row>
        </div>
      </div>

      <div v-if="status === 'MODO MANUAL'">
        <div class="customizer-section justify-content-between align-items-center">
          <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="modoDiscador"
              >
                Modo Discador
              </b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import useAppCustomizer from '@core/layouts/components/app-customizer/useAppCustomizer'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import {
  BLink,
  BButton,
  BRow,
  BCol,
  BFormInput,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '@/../axios-auth'

export default {
  components: {
    VuePerfectScrollbar,
    BLink,
    BButton,
    BRow,
    BCol,
    vSelect,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      // Customizer
      isCustomizerOpen,
    } = useAppCustomizer()

    // Perfect Scrollbar
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      // Customizer
      isCustomizerOpen,

      // Perfect Scrollbar
      perfectScrollbarSettings,
    }
  },
  data() {
    return {
      user: '',
      password: '',
      ramal: '',
      urlDiscador: process.env.VUE_APP_ROOT_FASTWAY,
      pausas: [],
      tabulacoes: [],
      discador: {
        status: '',
        status_complemento: '',
        tempo_status: '',
        cpf: '',
        chave_externa: '',
        telefone: '',
        campanha_ligacao: '',
      },
      numeroDiscagem: '',
      chaveDiscagem: '',
      cpfDiscagem: '',
      pausaSelecionada: null,
      tabulacaoSelecionada: null,
      pingHandlerId: null,
      getPausasTabulacoes: null,
    }
  },
  computed: {
    bgLink() {
      const { status } = this.discador
      if (status === '' || status === 'OFF') {
        return 'bg-danger'
      }
      return 'bg-success'
    },
    status() {
      // eslint-disable-next-line no-unused-vars
      const { status, status_complemento: statusComplemento, chave_externa: chaveExterna } = this.discador
      const { path } = this.$route

      if (status === 'EM PAUSA') {
        this.pausas.map(item => {
          if (item.nome === statusComplemento) {
            this.pausaSelecionada = item
          }
        })
      }

      if (status === 'FINALIZACAO') {
        // this.finalizar()
      }
      if (status === 'TRANSFERINDO') {
        this.emAtendimento()
      }

      if (status === 'EM ATENDIMENTO') {
        if (statusComplemento === 'ATIVO') {
          const route = `/negociacao/${chaveExterna}`
          if (route !== path) {
            this.$router.push({ name: 'negociacao-cliente', params: { id: chaveExterna } }).catch(() => {})
          }
        }
        this.getTabulacoes()
      }
      return status
    },
    statusComplemento() {
      const { status_complemento: statusComplemento } = this.discador

      return statusComplemento
    },
    tempoStatus() {
      const { tempo_status } = this.discador

      // eslint-disable-next-line camelcase
      return tempo_status
    },

    cpfConectado() {
      const { cpf } = this.discador

      // eslint-disable-next-line camelcase
      return cpf
    },

    telefoneConectado() {
      const { telefone } = this.discador

      // eslint-disable-next-line no-unused-vars
      if (telefone.length === 12 && parseInt(telefone.split('')[0]) === 0) {
        return this.phoneLabel(telefone.substring(1))
      } if (telefone.length === 11) {
        return this.phoneLabel(telefone)
      }
      return telefone
    },

    campanha() {
      const { campanha_ligacao } = this.discador

      // eslint-disable-next-line camelcase
      return campanha_ligacao
    },
    idCliente() {
      const { chave_externa: id } = this.discador

      return id
    },

    pausaDisabled() {
      const { pausaSelecionada } = this
      if (!pausaSelecionada) {
        return false
      }
      return true
    },

    tabulacaoDisabled() {
      const { tabulacaoSelecionada } = this
      if (!tabulacaoSelecionada) {
        return true
      }

      return false
    },
  },
  watch: {
    pausaSelecionada() {
      if (this.pausaSelecionada) {
        this.setPausa()
      }
    },
  },
  async created() {
    const { executivo_id: idExecutivo } = localStorage
    const findExec = await api.get(`api/v1/executivo/findId/${idExecutivo}`, {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        Accept: 'application/json',
      },
    }).then(res => res.data.dados)

    const { ramal, senha, usuario } = findExec
    this.user = usuario
    this.password = senha
    this.ramal = ramal
  },
  destroyed() {
    clearInterval(this.pingHandlerId)
    clearInterval(this.getPausasTabulacoes)
  },
  methods: {
    getPausas() {
      api.get(`${this.urlDiscador}pausas.php`, {
        params: {
          json: true,
          operador: this.user,
        },
      }).then(res => {
        const pausas = []
        const { disposition } = res.data.dispositions
        disposition.map(item => {
          pausas.push(item)
        })
        this.pausas = pausas
      })
    },
    getTabulacoes() {
      api.get(`${this.urlDiscador}tabulacoes.php`, {
        params: {
          json: true,
          operador: this.user,
        },
      }).then(res => {
        const tabulacoes = []
        if (res.data) {
          const { disposition } = res.data.dispositions
          disposition.map(item => {
            tabulacoes.push(item)
          })
          this.tabulacoes = tabulacoes
        }
      })
    },
    async ping() {
      this.pingHandlerId = setInterval(async () => {
        await api.get(`${this.urlDiscador}`, {
          params: {
            comando: 'PING',
            json: true,
            operador: this.user,
          },
        }).then(res => {
          const { discador } = this
          const { data } = res
          const discKeys = Object.keys(discador)
          discKeys.map(key => {
            this.discador[key] = data[key]
          })
        })
      }, 1000)
      await this.getPausas()
    },
    async login() {
      const { user: usuario, password: senha, ramal } = this
      if (ramal && senha && usuario) {
        api.get(this.urlDiscador, {
          params: {
            comando: 'LOGIN',
            senha,
            ramal,
            json: true,
            operador: usuario,
          },
        })
      } else {
        this.$swal({
          title: 'Error!',
          html: `
            <p>DADOS FORNECIDOS INVÁLIDOS</p>
               - USUÁRIO: ${usuario || 'nulo'} <br>
               - RAMAL: ${ramal || 'nulo'} <br>
               - SENHA: ${senha ? '*' * senha.length : 'nulo'}
          `,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
    setPausa() {
      api.get(this.urlDiscador, {
        params: {
          comando: 'PAUSA',
          subcomando: this.pausaSelecionada.nome,
          pausa_id: this.pausaSelecionada.id,
          json: true,
          operador: this.user,
        },
      })
    },
    fimPausa() {
      api.get(this.urlDiscador, {
        params: {
          comando: 'FIM PAUSA',
          json: true,
          operador: this.user,
        },
      }).then(() => {
        this.pausaSelecionada = null
      })
    },

    logoff() {
      api.get(this.urlDiscador, {
        params: {
          comando: 'SAIR',
          json: true,
          operador: this.user,
        },
      })
    },

    setDiscagemManual() {
      api.get(this.urlDiscador, {
        params: {
          comando: 'MODO MANUAL',
          json: true,
          operador: this.user,
        },
      })
    },

    emAtendimento() {
      api.get(this.urlDiscador, {
        params: {
          comando: 'EM ATENDIMENTO',
          operador: this.user,
          senha: this.password,
        },
      })
    },

    modoDiscador() {
      api.get(this.urlDiscador, {
        params: {
          comando: 'MODO DISCADOR',
          operador: this.user,
        },
      })
    },

    cancelaDiscagem(subcomando) {
      api.get(this.urlDiscador, {
        params: {
          comando: 'CANCELA DISCAGEM MANUAL',
          subcomando,
          json: true,
          operador: this.user,
        },
      })
    },

    discarManual() {
      const {
        numeroDiscagem,
        urlDiscador,
        chaveDiscagem,
        cpfDiscagem,
      } = this

      api.get(urlDiscador, {
        params: {
          comando: 'DISCAGEM MANUAL',
          ctt_fone: numeroDiscagem,
          ctt_chave: chaveDiscagem,
          ctt_cpf: cpfDiscagem,
          operador: this.user,
        },
      })
    },

    EncerrarLigacao() {
      const subcomando = this.tabulacaoSelecionada.nome
      api.get(this.urlDiscador, {
        params: {
          comando: 'ENCERRAR',
          subcomando,
          operador: this.user,
        },
      }).then(() => {
        this.createEventTabulacao()
      })
    },

    createEventTabulacao() {
      const { tabulacaoSelecionada } = this
      const { chave_externa: chaveExterna, telefone } = this.discador
      const allowIdsTab = [4, 3, 1]
      if (allowIdsTab.includes(parseInt(tabulacaoSelecionada.id))) {
        const body = {
          descricao: `
            <p> ${tabulacaoSelecionada.nome} - <strong>${telefone}</strong> </p>
          `,
          cliente_id: chaveExterna,
          // eslint-disable-next-line radix
          usuario_empresas_id: parseInt(localStorage.getItem('userId')),
          motivo_evento_id: 4,
          credores_id: null,
        }
        api.post('api/v1/eventos/store', body, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          },
        }).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sucesso',
              icon: 'CheckIcon',
              text: 'Evento criado com sucesso',
              variant: 'success',
            },
          },
          {
            position: 'top-right',
          })
          this.$store.dispatch('negociacao/getInfoCliente', chaveExterna)
        })
      }
    },

    finalizar() {
      api.get(this.urlDiscador, {
        params: {
          comando: 'FINALIZADO',
          json: true,
          count: 1056,
          operador: this.user,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.customizer-section {
  padding: 1.5rem;
    border-bottom: 1px solid #ebe9f1;

  .dark-layout & {
    border-color: $theme-dark-border-color;
  }

  #skin-radio-group ::v-deep {
    .custom-control-inline {
      margin-right: 0.7rem;
    }
  }

  .form-group {
    margin-bottom: 1.5rem;;
    &:last-of-type {
    margin-bottom: 0;
    }
    ::v-deep legend {
      font-weight: 500;
    }
  }
}

.mark-active {
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}

.ps-customizer-area {
  height: calc(100% - 83px)
}
</style>
