<template>
  <layout-vertical>

    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <Discador
      v-if="showCustomizer && isExecutivo"
      slot="customizer"
    />
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import { $themeConfig } from '@themeConfig'
import Discador from '@/views/cobranca/Discador/Disc.vue'
import Navbar from '../components/Navbar.vue'

export default {
  components: {
    Discador,
    LayoutVertical,
    Navbar,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
      isExecutivo: true,
    }
  },
  created() {
    const { executivo_id: executivoId } = localStorage
    if (!executivoId || executivoId === 'false') {
      this.isExecutivo = false
    }
  },
}
</script>
